@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;800&display=swap");
* {
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  background: #FFFFFF;
}

.cat__container {
  padding: 0 3.7rem;
}
.cat__bg {
  background: url("/assets/img/line.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left bottom;
}
.cat__split {
  display: flex;
}
.cat__split__sidebar {
  width: 304px;
  flex-shrink: 0;
  margin: 0 30px 0 0;
}
.cat__split__main {
  flex-grow: 1;
}
.cat__split__main__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 188px);
  justify-content: space-between;
}
.cat__split__main__item__split {
  display: flex;
}
.cat__split__main__item__split__title {
  display: none;
}
.cat__split__main__item__details, .cat__split__main__item__image {
  width: 50%;
}
.cat__split__main__item__image {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.cat__split__main__item__image img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  align-self: center;
}
.cat__split__main__item__title {
  font-family: Raleway, sans-serif;
  font-weight: 800;
  font-size: 63px;
  line-height: 74px;
  color: #030D4F;
  margin-bottom: 100px;
}
.cat__split__main__item__specs {
  position: relative;
}
.cat__split__main__item__specs__circle {
  position: absolute;
  left: -83px;
  top: -83px;
  z-index: 1;
  width: 438px;
  height: 438px;
  background: #F4F4F4;
  border-radius: 249px;
  pointer-events: none;
}
.cat__split__main__item__specs ul {
  position: relative;
  z-index: 2;
  list-style: none;
  padding: 0 0 0 70px;
  margin: 0;
  width: 465px;
  max-width: 100%;
}
.cat__split__main__item__specs ul li {
  font-family: Raleway, sans-serif;
  font-weight: 300;
  font-size: 25px;
  line-height: 35px;
  color: #000000;
  position: relative;
  margin-bottom: 10px;
}
.cat__split__main__item__specs ul li:before {
  content: "";
  display: inline-flex;
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background: black;
  position: absolute;
  left: -25px;
  top: 15px;
}
.cat__split__main__item__benefits {
  display: grid;
  grid-template-columns: repeat(3, 300px);
  position: relative;
  padding: 125px 0 0 125px;
}
.cat__split__main__item__benefit {
  width: 300px;
  margin-bottom: 56px;
  font-family: Raleway, sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 27px;
  color: #000000;
}
.cat__split__main__item__benefit-circle {
  position: absolute;
  left: 90px;
  top: 100px;
  pointer-events: none;
  width: 28px;
  height: 28px;
}
.cat__split__main__item__benefit-circle svg {
  width: 100%;
  height: 100%;
}
.cat__category-list {
  display: flex;
  flex-direction: column;
  padding: 30px 10px 5px;
  background: #fff;
  border-radius: 5px;
  position: sticky;
}
.cat__category-list.locked {
  position: fixed;
  top: 0;
  width: 284px;
  z-index: 999;
}
.cat__category-list a {
  font-family: Raleway, sans-serif;
  font-weight: 400;
  font-size: 23px;
  line-height: 25px;
  color: rgba(3, 13, 79, 0.4);
  text-decoration: none;
}
.cat__category-list a:hover {
  color: #030D4F;
}
.cat__category-list__header {
  margin-bottom: 22px;
  cursor: pointer;
  user-select: none;
  padding-right: 45px;
}
.cat__category-list__header__chevron {
  display: none;
}
.cat__category-list__header a {
  color: #030D4F;
}
.cat__category-list__container ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.cat__category-list__container ul li {
  display: inline-flex;
  margin-bottom: 22px;
}
.cat__item {
  width: 188px;
  height: 188px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.cat__item img {
  object-fit: contain;
  align-self: center;
  pointer-events: none;
}
.cat__item:focus, .cat__item:active, .cat__item:hover {
  background: url("/assets/img/border.png");
  background-size: 100% 100%;
}
.cat__spacer {
  display: flex;
  flex-wrap: wrap;
  padding: 55px 0 55px 314px;
}
.cat__spacer__item {
  display: inline-flex;
  flex-shrink: 0;
  width: 70px;
  height: 70px;
  margin: 10px;
}
.cat__spacer__item svg {
  width: 100%;
  height: 100%;
}
.cat__support {
  position: fixed;
  right: 0;
  top: 150px;
  display: inline-flex;
  width: 85px;
  height: 75px;
}
.cat__support__backdrop {
  position: relative;
  z-index: 1;
}
.cat__support__border {
  position: absolute;
  z-index: 2;
  pointer-events: none;
  display: inline-flex;
  height: 75px;
  width: 75px;
}
.cat__support__border svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cat__support a {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 75px;
}
.cat__banner {
  padding: 0 0 0 500px;
  background: #CC1142;
  overflow: hidden;
  height: 411px;
  display: flex;
}
.cat__banner__split {
  display: flex;
}
.cat__banner__split__overlay {
  display: none;
}
.cat__banner__split__details {
  width: 500px;
  padding: 58px 0 0;
}
.cat__banner__split__detail {
  margin-bottom: 18px;
  font-family: Raleway, sans-serif;
  font-weight: 400;
  font-size: 23px;
  line-height: 25px;
  color: #FFFFFF;
}
.cat__banner__split__title {
  font-family: Raleway, sans-serif;
  font-weight: 800;
  font-size: 63px;
  line-height: 74px;
  color: #FFFFFF;
  text-decoration: none;
}
.cat__banner__split__image {
  position: relative;
  overflow: hidden;
  width: 534px;
  height: 411px;
}
.cat__banner__split__image__overlay {
  position: absolute;
  top: -167px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}
.cat__banner__split__image__overlay img {
  width: 334px;
  height: 334px;
}
.cat__banner__split__image__element {
  position: absolute;
  z-index: 2;
  bottom: -68px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1199px) {
  .cat__container {
    padding: 0 2.7rem;
  }
}
@media (max-width: 1499px) {
  .cat__banner {
    padding: 0 50px;
    justify-content: center;
  }
  .cat__split {
    display: flex;
  }
  .cat__split__main__item__title {
    font-size: 40px;
    line-height: unset;
  }
  .cat__split__main__item__benefits {
    grid-template-columns: repeat(auto-fill, 300px);
  }
}
@media (max-width: 1111px) {
  .cat__split__main__item {
    width: 100%;
  }
  .cat__split__main__item__title {
    display: none;
  }
  .cat__split__main__item__details {
    width: 380px;
    max-width: 100%;
    padding-bottom: 80px;
  }
  .cat__split__main__item__specs {
    padding: 0 0 0 26px;
  }
  .cat__split__main__item__specs__circle {
    width: 268px;
    height: 268px;
    border-radius: 249px;
    left: 0px;
    top: -68px;
  }
  .cat__split__main__item__specs ul {
    width: 100%;
    padding-left: 26px;
  }
  .cat__split__main__item__specs ul li {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .cat__split__main__item__specs ul li:before {
    width: 3px;
    height: 3px;
    border-radius: 2px;
    left: -15px;
    top: 9px;
  }
  .cat__split__main__item__split {
    align-items: center;
    flex-direction: column;
  }
  .cat__split__main__item__split__title {
    display: inline-flex;
    width: 100%;
    margin-bottom: 30px;
    font-family: Raleway, sans-serif;
    font-weight: 800;
    font-size: 29px;
    line-height: 34px;
    color: #030D4F;
    max-width: 329px;
    padding-top: 50px;
  }
  .cat__split__main__item__image {
    width: 380px;
    max-width: 100%;
    margin-bottom: 87px;
  }
  .cat__split__main__item__benefit-circle {
    left: 145px;
    top: 55px;
  }
  .cat__split__main__item__benefits {
    padding: 80px 40px 0;
    justify-content: center;
    grid-template-columns: repeat(1, 300px);
  }
  .cat__banner {
    padding: 0 33px;
    height: unset;
  }
  .cat__banner__split {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }
  .cat__banner__split__overlay {
    display: inline-flex;
    width: 153px;
    height: 153px;
    top: -77px;
    position: absolute;
    right: 30px;
  }
  .cat__banner__split__overlay img {
    max-width: 100%;
    max-height: 100%;
    align-self: center;
    object-fit: contain;
  }
  .cat__banner__split__details {
    padding: 25px 0;
  }
  .cat__banner__split__detail {
    margin-bottom: 25px;
    font-size: 15px;
    line-height: 25px;
  }
  .cat__banner__split__title {
    display: inline-flex;
    width: 229px;
    max-width: 100%;
    font-size: 29px;
    line-height: 34px;
  }
  .cat__banner__split__image {
    height: 152px;
    max-width: 100%;
  }
  .cat__banner__split__image__overlay {
    display: none;
  }
  .cat__banner__split__image__element {
    height: 183px;
    width: auto;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 789px) {
  .cat__banner__split__details {
    width: 100%;
  }
  .cat__split {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .cat__split__main, .cat__split__sidebar {
    width: 100%;
    margin-right: 0;
  }
  .cat__split__main__item__benefit-circle {
    left: 21px;
  }
  .cat__split__sidebar.extrapadding {
    padding-top: 69px;
  }
  .cat__category-list {
    position: relative;
  }
  .cat__category-list.locked {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    width: 100%;
    padding-left: 2.7rem;
    padding-right: 2.7rem;
  }
  .cat__category-list.locked .cat__category-list__container {
    padding-left: 2.7rem;
    padding-right: 2.7rem;
    left: 0;
    right: 0;
  }
  .cat__category-list__header {
    position: relative;
    margin-bottom: 14px;
  }
  .cat__category-list__header a {
    font-size: 15px;
  }
  .cat__category-list__header__chevron {
    display: inline-flex;
    position: absolute;
    right: 0;
    top: 5px;
    width: 38px;
    height: 17px;
    transition: all 0.3s ease-out;
  }
  .cat__category-list.list-open-js .cat__category-list__header__chevron {
    transform: rotate(180deg);
  }
  .cat__category-list__container {
    position: absolute;
    top: 100%;
    padding: 0 2.7rem;
    left: -2.7rem;
    right: -2.7rem;
    display: none;
    background: #FFFFFF;
    box-shadow: 0px 9px 11px rgba(0, 0, 0, 0.1);
  }
  .cat__category-list__container ul li {
    margin-bottom: 14px;
  }
  .cat__category-list__container ul li a {
    font-size: 15px;
  }
  .cat__category-list.list-open-js .cat__category-list__container {
    display: block;
  }
  .cat__spacer {
    padding-left: 0;
  }
  .cat__item {
    width: 130px;
    height: 130px;
  }
  .cat__item img {
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
  }
}