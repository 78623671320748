@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;800&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  background: #FFFFFF;
}

.cat {
  &__container {
    padding: 0 3.7rem;
  }

  &__bg {
    background: url('/assets/img/line.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: left bottom;
  }

  &__split {
    display: flex;

    &__sidebar {
      width: 304px;
      flex-shrink: 0;
      margin: 0 30px 0 0;
    }

    &__main {
      flex-grow: 1;

      &__list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, 188px);
        justify-content: space-between;
      }

      &__item {
        &__split {
          display: flex;

          &__title {
            display: none;
          }
        }

        &__details,
        &__image {
          width: 50%;
        }

        &__image {
          display: flex;
          justify-content: center;
          align-items: flex-start;

          img {
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
            align-self: center;
          }
        }

        &__title {
          font-family: Raleway, sans-serif;
          font-weight: 800;
          font-size: 63px;
          line-height: 74px;
          color: #030D4F;
          margin-bottom: 100px;
        }

        &__specs {
          position: relative;

          &__circle {
            position: absolute;
            left: -83px;
            top: -83px;
            z-index: 1;
            width: 438px;
            height: 438px;
            background: #F4F4F4;
            border-radius: 249px;
            pointer-events: none;
          }

          ul {
            position: relative;
            z-index: 2;
            list-style: none;
            padding: 0 0 0 70px;
            margin: 0;
            width: 465px;
            max-width: 100%;

            li {
              font-family: Raleway, sans-serif;
              font-weight: 300;
              font-size: 25px;
              line-height: 35px;
              color: #000000;
              position: relative;
              margin-bottom: 10px;

              &:before {
                content: "";
                display: inline-flex;
                width: 5px;
                height: 5px;
                border-radius: 3px;
                background: black;
                position: absolute;
                left: -25px;
                top: 15px;
              }
            }
          }
        }

        &__benefits {
          display: grid;
          grid-template-columns: repeat(3, 300px);
          position: relative;
          padding: 125px 0 0 125px;
        }

        &__benefit {
          width: 300px;
          margin-bottom: 56px;
          font-family: Raleway, sans-serif;
          font-weight: 300;
          font-size: 15px;
          line-height: 27px;
          color: #000000;
        }

        &__benefit-circle {
          position: absolute;
          left: 90px;
          top: 100px;
          pointer-events: none;
          width: 28px;
          height: 28px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  &__category-list {
    display: flex;
    flex-direction: column;
    padding: 30px 10px 5px;
    background: #fff;
    border-radius: 5px;
    position: sticky;

    &.locked {
      position: fixed;
      top: 0;
      width: 284px;
      z-index: 999;
    }

    a {
      font-family: Raleway, sans-serif;
      font-weight: 400;
      font-size: 23px;
      line-height: 25px;
      color: rgba(3, 13, 79, 0.4);
      text-decoration: none;

      &:hover {
        color: #030D4F;
      }
    }

    &__header {
      margin-bottom: 22px;
      cursor: pointer;
      user-select: none;
      padding-right: 45px;

      &__chevron {
        display: none;
      }

      a {
        color: #030D4F;
      }
    }

    &__container {
      ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;

        li {
          display: inline-flex;
          margin-bottom: 22px;
        }
      }
    }
  }

  &__item {
    width: 188px;
    height: 188px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    img {
      object-fit: contain;
      align-self: center;
      pointer-events: none;
    }

    &:focus,
    &:active,
    &:hover {
      background: url('/assets/img/border.png');
      background-size: 100% 100%;
    }
  }

  &__spacer {
    display: flex;
    flex-wrap: wrap;
    padding: 55px 0 55px 314px;

    &__item {
      display: inline-flex;
      flex-shrink: 0;
      width: 70px;
      height: 70px;
      margin: 10px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__support {
    position: fixed;
    right: 0;
    top: 150px;
    display: inline-flex;
    width: 85px;
    height: 75px;

    &__backdrop {
      position: relative;
      z-index: 1;
    }

    &__border {
      position: absolute;
      z-index: 2;
      pointer-events: none;
      display: inline-flex;
      height: 75px;
      width: 75px;

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    a {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 75px;
      width: 75px;
    }
  }

  &__banner {
    padding: 0 0 0 500px;
    background: #CC1142;
    overflow: hidden;
    height: 411px;
    display: flex;

    &__split {
      display: flex;

      &__overlay {
        display: none;
      }

      &__details {
        width: 500px;
        padding: 58px 0 0
      }

      &__detail {
        margin-bottom: 18px;
        font-family: Raleway, sans-serif;
        font-weight: 400;
        font-size: 23px;
        line-height: 25px;
        color: #FFFFFF;
      }

      &__title {
        font-family: Raleway, sans-serif;
        font-weight: 800;
        font-size: 63px;
        line-height: 74px;
        color: #FFFFFF;
        text-decoration: none;
      }

      &__image {
        position: relative;
        overflow: hidden;
        width: 534px;
        height: 411px;

        &__overlay {
          position: absolute;
          top: -167px;
          z-index: 1;
          left: 50%;
          transform: translateX(-50%);

          img {
            width: 334px;
            height: 334px;
          }
        }

        &__element {
          position: absolute;
          z-index: 2;
          bottom: -68px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .cat {
    &__container {
      padding: 0 2.7rem;
    }
  }
}

@media (max-width: 1499px) {
  .cat {
    &__banner {
      padding: 0 50px;
      justify-content: center;
    }

    &__split {
      display: flex;

      &__main {
        &__item {
          &__title {
            font-size: 40px;
            line-height: unset;
          }

          &__benefits {
            grid-template-columns: repeat(auto-fill, 300px);
          }
        }
      }
    }
  }
}

@media (max-width: 1111px) {
  .cat {
    &__split {
      &__main {
        &__item {
          width: 100%;

          &__title {
            display: none;
          }

          &__details {
            width: 380px;
            max-width: 100%;
            padding-bottom: 80px;
          }

          &__specs {
            padding: 0 0 0 26px;

            &__circle {
              width: 268px;
              height: 268px;
              border-radius: 249px;
              left: 0px;
              top: -68px;
            }

            ul {
              width: 100%;
              padding-left: 26px;

              li {
                font-size: 15px;
                line-height: 20px;
                margin-bottom: 10px;

                &:before {
                  width: 3px;
                  height: 3px;
                  border-radius: 2px;
                  left: -15px;
                  top: 9px;
                }
              }
            }
          }

          &__split {
            align-items: center;
            flex-direction: column;

            &__title {
              display: inline-flex;
              width: 100%;
              margin-bottom: 30px;
              font-family: Raleway, sans-serif;
              font-weight: 800;
              font-size: 29px;
              line-height: 34px;
              color: #030D4F;
              max-width: 329px;
              padding-top: 50px;
            }
          }

          &__image {
            width: 380px;
            max-width: 100%;
            margin-bottom: 87px;
          }

          &__benefit-circle {
            left: 145px;
            top: 55px;
          }

          &__benefits {
            padding: 80px 40px 0;
            justify-content: center;
            grid-template-columns: repeat(1, 300px);
          }
        }
      }
    }

    &__banner {
      padding: 0 33px;
      height: unset;

      &__split {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
        justify-content: center;
        align-items: center;
        max-width: 100%;

        &__overlay {
          display: inline-flex;
          width: 153px;
          height: 153px;
          top: -77px;
          position: absolute;
          right: 30px;

          img {
            max-width: 100%;
            max-height: 100%;
            align-self: center;
            object-fit: contain;
          }
        }

        &__details {
          padding: 25px 0;
        }

        &__detail {
          margin-bottom: 25px;
          font-size: 15px;
          line-height: 25px;
        }

        &__title {
          display: inline-flex;
          width: 229px;
          max-width: 100%;
          font-size: 29px;
          line-height: 34px;
        }

        &__image {
          height: 152px;
          max-width: 100%;

          &__overlay {
            display: none;
          }

          &__element {
            height: 183px;
            width: auto;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}

@media (max-width: 789px) {
  .cat {
    &__banner {
      &__split {
        &__details {
          width: 100%;
        }
      }
    }
    
    &__split {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__main,
      &__sidebar {
        width: 100%;
        margin-right: 0;
      }

      &__main {
        &__item {
          &__benefit-circle {
            left: 21px;
          }
        }
      }

      &__sidebar.extrapadding {
        padding-top: 69px;
      }
    }

    &__category-list {
      position: relative;

      &.locked {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: #fff;
        width: 100%;
        padding-left: 2.7rem;
        padding-right: 2.7rem;

        & .cat__category-list__container {
          padding-left: 2.7rem;
          padding-right: 2.7rem;
          left: 0;
          right: 0;
        }
      }

      &__header {
        position: relative;
        margin-bottom: 14px;

        a {
          font-size: 15px;
        }

        &__chevron {
          display: inline-flex;
          position: absolute;
          right: 0;
          top: 5px;
          width: 38px;
          height: 17px;
          transition: all 0.3s ease-out;
        }
      }

      &.list-open-js {
        .cat__category-list__header__chevron {
          transform: rotate(180deg);
        }
      }

      &__container {
        position: absolute;
        top: 100%;
        padding: 0 2.7rem;
        left: -2.7rem;
        right: -2.7rem;
        display: none;
        background: #FFFFFF;
        box-shadow: 0px 9px 11px rgba(0, 0, 0, 0.1);

        ul {
          li {
            margin-bottom: 14px;

            a {
              font-size: 15px;
            }
          }
        }
      }

      &.list-open-js {
        .cat__category-list__container {
          display: block;
        }
      }
    }

    &__spacer {
      padding-left: 0;
    }

    &__item {
      width: 130px;
      height: 130px;

      img {
        max-width: calc(100% - 20px);
        max-height: calc(100% - 20px);
      }
    }
  }
}